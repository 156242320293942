import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Scrollchor from 'react-scrollchor'


// const Header = ({ siteTitle }) => (
class Header extends React.Component {
  state = {
    isActive: false,
  }

  toggleNav = () => {
    this.setState(prevState => ({
      isActive: !prevState.isActive
    }))
  }

  render() {
    return (
      <nav className="navbar is-fixed-top has-shadow">

          <div className="navbar-brand is-vertical-center">
            <div className="navbar-item">
              <Scrollchor to="#top" animate={{offset: 0, duration: 600}}>
                <img src="/img/bsh-logo.png" width="200"/>
              </Scrollchor> 
            </div>

            <a role="button" onClick={this.toggleNav} className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarMenu">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
          <div id="navbarMenu" className={ this.state.isActive ? 'navbar-menu is-active' : 'navbar-menu'}>
            <div className="navbar-end">
              <div className="navbar-item">
                <Scrollchor to="#private_equity" animate={{offset: -20, duration: 600}}>
                  Private Equity
                </Scrollchor>
              </div>
              <div className="navbar-item">
                <Scrollchor to="#real_estate" animate={{offset: -20, duration: 600}}>
                  Real Estate
                </Scrollchor>
              </div>
              <div className="navbar-item">
                <Scrollchor to="#giving_back" animate={{offset: -40, duration: 800}}>
                  Giving Back
                </Scrollchor>
              </div>
              <div className="navbar-item">
                <Scrollchor to="#contact" animate={{offset: -40, duration: 800}}>
                  Contact
                </Scrollchor> 
              </div>
            </div>
          </div>

      </nav>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
